body {
  margin: 0;
  font-family: 'CircularStd', sans-serif;
  line-height: 1.5;
  background-color: #F6F7FB;
}

@media screen and (max-width: 640px) {
  body {
    background-color: #FFFFFF;
  }
}

section h1 {
  font-size: 24px;
}
